import { Component } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { Data, RouterOutlet } from '@angular/router';
import { slideInAnimation } from './utility/animations';
import { HostBinding } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
  ]
})
export class AppComponent {
  title = 'blogsite';
  @HostBinding('@.disabled') private disabled = true;

  prepareRoute(outlet: RouterOutlet) {

    //https://www.codegrepper.com/code-examples/javascript/check+if+user+switches+to+mobile+view+angular
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.disabled = true
    }
    else{
      this.disabled = false
    }
    console.log(outlet.activatedRouteData)
    return outlet.activatedRouteData['animation'];
  }

}
