import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { ProjectComponent } from './project/project.component';
import { TutorialsComponent } from './tutorials/tutorials.component';
import { PrivacyComponent } from './privacy/privacy.component';


const routes: Routes = [

  
  { path: 'project', component: ProjectComponent,
  data: { animation: 'FilterPage' }  },
  { path: 'about', component: AboutComponent,
  data: { animation: 'AboutPage' }  },
  { path: '', component: HomeComponent,
  data: { animation: 'HomePage' }  },
  { path: '**', component: HomeComponent,
  data: { animation: 'HomePage' }  },


  {path:'http://marclaffan.com/posts/26', component: PrivacyComponent}

];

@NgModule({
  imports: [BrowserAnimationsModule,RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
