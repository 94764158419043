import { Component, OnInit, Inject, Input } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { Animations } from '../utility/animations';
import { ProjectImageLinkComponent } from '../project-image-link/project-image-link.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogPopupComponent } from '../dialog-popup/dialog-popup.component';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  animations: [ Animations.imageLinkTrigger, Animations.buttonLinkTrigger ],
})
export class ProjectComponent  {

  dialog : DialogPopupComponent;
  
  isRAImageOpen = true;
  isRAButtonOpen = false;

  isEDImageOpen = true;
  isEDButtonOpen = false;

  isTWImageOpen = true;
  isTWButtonOpen = false;

  isOVImageOpen = true;
  isOVButtonOpen = false;

  constructor() {
    //this.dialog = new DialogPopupComponent();
  }

  toggle() {
    this.isRAImageOpen = !this.isRAImageOpen;
    this.isRAButtonOpen = !this.isRAButtonOpen;

    this.isEDImageOpen = !this.isEDImageOpen;
    this.isEDButtonOpen = !this.isEDButtonOpen;

    this.isTWImageOpen = !this.isTWImageOpen;
    this.isTWButtonOpen = !this.isTWButtonOpen;

    this.isOVImageOpen = !this.isOVImageOpen;
    this.isOVButtonOpen = !this.isOVButtonOpen;
  }

  openDialog(){
    this.dialog.openDialog()
  }

}