import { ElementRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dialog-popup',
  templateUrl: './dialog-popup.component.html',
  styleUrls: ['./dialog-popup.component.scss']
})
export class DialogPopupComponent implements OnInit {

  
  routeQueryParams$: Subscription;
  
  constructor(public dialog: MatDialog, private _el: ElementRef ,
    private route: ActivatedRoute) {
      this.routeQueryParams$ = route.queryParams.subscribe(params => {
        if (params['dialog']) {
          this.openDialog();
        }
      });
      
     }

  ngOnInit() {
    this._el.nativeElement.focus();
  }

  openDialog(): void {
    let config = new MatDialogConfig();
  config = {
    position: {
      top: '15em',
      right: '30em'
    },
    height: '12em',
    width: '40vw',
    panelClass: 'full-screen-modal',
    restoreFocus: false };
    const dialogRef = this.dialog.open(DialogContentExampleDialog,
      {panelClass: 'popup'});
      
      
      //config);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}



@Component({
selector: 'dialog-content-example-dialog',
templateUrl: 'dialog-content-example-dialog.html',
})

export class DialogContentExampleDialog {}



