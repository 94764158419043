import { Component, OnInit } from '@angular/core';
import { Tutorial } from '../model/tutorial';



@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss']
})

export class TutorialsComponent implements OnInit {

  tuts: Tutorial[] = [
    { id: 0, title: 'Hash Table', content: "For this post we'll be diving into the Hash Table. The hash table is essentially an array where the index can be anything, doesn't have to be sequential, and is unique. Hash tables use a technique called \"hashing\" in order to derive values that will be used as an index, or \"key\". A hashing algorithm should be good enough to accomodate duplicates and deal with collisions. Our approach in this post will involve an array of objects called HashTableArray. Don't be fooled by the name however, as the HashTableArray class uses a LinkedList to keep tracks of the nodes it stores. We'll be going over the structure of what a hash table looks like, but we won't be delving into the minutia or covering the different hashing techniques in much depth at all. This post is simply meant to give you an overview of this particular data structure." },
    { id: 1, title: 'Binary Tree', content: "Today we'll be looking at how the Binary Tree works. We also need an additional class, a Binary Tree Node class because our nodes are just values this time, they contain additional data required by the Binary Tree in order for it to operate. We'll start off first with the building blocks of the binary tree, the node:"  },
    { id: 2, title: 'Quick Sort', content: "The last sorting algorithm we'll be looking at the now in this series is the Quick Sort. The quick sort is another divide and conquer algorithm, but this time we iterate over the array repeatedly, picking a \"pivot\" element every iteration. We rearrange the surrounding elements such that every value to the left is lower and every value to the right is higher, but do not actually sort the left or right sides other than making sure that each of  said sides elements are higher/lower than the pivot value." },
    { id: 3, title: 'Merge Sort', content: "Happy new year! What better way to kick off the new year than learning about (Or getting a refresher on) the Merge Sort algorithm, our first divide and conquer algorithm. The merge sort works by splitting up the array to be sorted in halves iteratively until it reaches the point that each element is contained within it's own array. It then reconstructs the original array step-by-step (Doubling each of the single-element arrays, as opposed to the halving we previously performed) sorting the array at each reconstruction, until the array is both whole again and sorted!" },
    { id: 4, title: 'Insertion Sort', content: "For Decembers post, we'll be diving into the Insertion Sort algorithm. It performs very similarly to the Selection Sort, so let's take a look:" },
    { id: 5, title: 'Selection Sort', content: "This time we'll be taking a look at the Selection Sort algorithm. The selection sort searches for the lowest value for the remaining values in an array and inserts it into the leftmost unsorted index so far. Basically it starts on the left, grabs the lowest value every iteration, then shifts it over to the leftmost index of the unsorted side." },
    { id: 6, title: 'Hash Table', content: "For this post we'll be diving into the Hash Table. The hash table is essentially an array where the index can be anything, doesn't have to be sequential, and is unique. Hash tables use a technique called \"hashing\" in order to derive values that will be used as an index, or \"key\". A hashing algorithm should be good enough to accomodate duplicates and deal with collisions. Our approach in this post will involve an array of objects called HashTableArray. Don't be fooled by the name however, as the HashTableArray class uses a LinkedList to keep tracks of the nodes it stores. We'll be going over the structure of what a hash table looks like, but we won't be delving into the minutia or covering the different hashing techniques in much depth at all. This post is simply meant to give you an overview of this particular data structure." },
    { id: 7, title: 'Binary Tree', content: "Today we'll be looking at how the Binary Tree works. We also need an additional class, a Binary Tree Node class because our nodes are just values this time, they contain additional data required by the Binary Tree in order for it to operate. We'll start off first with the building blocks of the binary tree, the node:"  },
    { id: 8, title: 'Quick Sort', content: "The last sorting algorithm we'll be looking at the now in this series is the Quick Sort. The quick sort is another divide and conquer algorithm, but this time we iterate over the array repeatedly, picking a \"pivot\" element every iteration. We rearrange the surrounding elements such that every value to the left is lower and every value to the right is higher, but do not actually sort the left or right sides other than making sure that each of  said sides elements are higher/lower than the pivot value." },
    { id: 9, title: 'Merge Sort', content: "Happy new year! What better way to kick off the new year than learning about (Or getting a refresher on) the Merge Sort algorithm, our first divide and conquer algorithm. The merge sort works by splitting up the array to be sorted in halves iteratively until it reaches the point that each element is contained within it's own array. It then reconstructs the original array step-by-step (Doubling each of the single-element arrays, as opposed to the halving we previously performed) sorting the array at each reconstruction, until the array is both whole again and sorted!" },
    { id: 10, title: 'Insertion Sort', content: "For Decembers post, we'll be diving into the Insertion Sort algorithm. It performs very similarly to the Selection Sort, so let's take a look:" },
    { id: 11, title: 'Selection Sort', content: "This time we'll be taking a look at the Selection Sort algorithm. The selection sort searches for the lowest value for the remaining values in an array and inserts it into the leftmost unsorted index so far. Basically it starts on the left, grabs the lowest value every iteration, then shifts it over to the leftmost index of the unsorted side." }
  ];

  displayedColumns: string[] = ['title-b'];
  dataSource = this.tuts;

  selectedTut : Tutorial;
  
  onSelect(id: number): void {
    this.selectedTut = this.tuts[id];
  }

  constructor() { this.selectedTut = this.tuts[0]}

  ngOnInit() {
  }

}
